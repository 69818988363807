@import url(https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=block);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=block);
.Icon {
  --color: currentColor;
  color: var(--color);
  display: flex;
}

.Icon > div {
  display: flex;
  border: solid 1px var(--color);
  border-radius: 4px;
  padding: 4px;
}

.Icon.hover:hover > div {
  color: var(--gold);
  border-color: white;
  background-color: white;
}

.Icon.gold {
  background-color: var(--gold);
  color: white;
}


.Modal.ImageSolo {
  display: flex;
  flex-direction: column;
}
.Modal.ImageSolo > .Images {
  padding: 32px;
  width: 100%;
  /* height: 100%; */
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Modal.ImageSolo .ImageElement {
  display: block;
  min-width: 200px;
  min-height: 200px;
  max-height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: whitesmoke;
}
.Modal.ImageSolo .ImageElement.format9x16 {
  aspect-ratio: 9/16;
}
.Modal.ImageSolo .ImageElement.format4x5 {
  aspect-ratio: 4/5;
  max-height: 75%;
}
.Modal.ImageSolo .ImageElement a {
  display: block;
  width: 100%;
  height: 100%;
}
.Modal.ImageSolo .ImageElement img,
.Modal.ImageSolo .ImageElement video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Modal.ImageSolo .ImageElement + .ImageElement {
  margin-left: 32px;
}



.Modal.ImageSolo .ImageElement .Download {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 12px;
  justify-content: flex-end;
  background-color: var(--light-blue);
  color: var(--dark-blue);
  opacity: .98;
  transition: all 0.1s ease-out;
}
.Modal.ImageSolo .ImageElement .Download span {
  font-size: 12px;
}
.Modal.ImageSolo .ImageElement .Download .Icon {
  margin-left: 8px;
}
.Modal.ImageSolo .ImageElement .Download:hover {
  color: var(--bright-blue);
}
.Modal.ImageSolo .ImageElement .Download.hidden {
  opacity: 0;
}

.Modal.ImageSolo footer {
  background-color: var(--blue);
  color: var(--gold);
  padding: 12px;
}
.Modal.ImageSolo footer > * + * {
  margin-left: 16px;
}
.Modal.ImageSolo footer a {
  font-size: 12px;
  color: inherit;
  transition: all .2s ease-out;
  text-decoration: none;
}
.Modal.ImageSolo footer a:hover {
  color: var(--gold-bright);
}
.Modal.ImageSolo footer a > * + * {
  margin-left: 10px;
}


.Modal.ImageSolo .CloseModal {
  position: absolute;
  right: 0;
  top: 0;
}


.Modal.ImageSolo .NameParts {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 8px;
  padding-right: 16px;
}
.Modal.ImageSolo .NameParts div.Part {
  display: flex;
  align-items: center;
  padding: 1px 0;
  border-bottom: solid 1px currentColor;
  border-top: solid 1px currentColor;
  cursor: pointer;
}
.Modal.ImageSolo .NameParts div.Part span {
  pointer-events: none;
  padding: 0;
}
.Modal.ImageSolo .NameParts div.Part.active {
  color: white;
  background-color: #fff2;
}

/* mobile */
.Modal.ImageComment {
  display: flex;
  padding: 64px;
  font-family: 'PT Sans', sans-serif;
}
.Modal.ImageComment > * {
  flex: 1 1;
}
.Modal.ImageComment > * + * {
  margin-left: 32px;
}

.Modal.ImageComment .Image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.Modal.ImageComment .Comments {
  display: flex;
  flex-direction: column;
  pointer-events: none;
}
.Modal.ImageComment button,
.Modal.ImageComment .Comment {
  pointer-events: all;
}
.Modal.ImageComment .Comment {
  display: flex;
  flex-direction: row;
}

.Modal.ImageComment .Comment textarea, 
.Modal.ImageComment .Comment input {
  -webkit-appearance: none;
          appearance: none;
  border: solid 1px #0003;
  border-radius: 2px;
  resize: none;
  font-family: inherit;
  padding: 4px 8px;
  outline: none;
  opacity: .75;
}
.Modal.ImageComment .Comment textarea:focus, 
.Modal.ImageComment .Comment input:focus {
  opacity: 1;
}
.Modal.ImageComment .Comment input {
  flex: 0 0 100px;
}
.Modal.ImageComment .Comment textarea {
  flex: 1 1;
}
.Modal.ImageComment .Comment textarea.modified, 
.Modal.ImageComment .Comment input.modified {
  border-color: #000c;
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ccc6;
  -webkit-backdrop-filter: blur(32px);
          backdrop-filter: blur(32px);
}
@supports not ((-webkit-backdrop-filter: blur(32px)) or (backdrop-filter: blur(32px))) {
  .Modal {
    background-color: #cccc;
  }
}
.Viewer .Thumbnail {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  font-size: 12px;
}

.Viewer .Thumbnail p {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PT Sans Narrow', sans-serif;
}
.Viewer .Thumbnail p.minimized {
  /* font-size: .66em; */
}

.Viewer .Thumbnail > div {
  flex: 1 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Viewer .Thumbnail .Wrapper img,
.Viewer .Thumbnail .Wrapper video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.Viewer .Thumbnail .Border {
  transition: .2s;
  pointer-events: none;
}
@media print {
  .Viewer .Thumbnail .Border,
  .Viewer .Thumbnail .Actions {
    display: none;
  }
}
.Viewer .Thumbnail .Wrapper .Actions {
  padding: 8px;
  align-items: flex-start;
  pointer-events: none;
}
.Viewer .Thumbnail .Wrapper .Actions > * {
  visibility: hidden;
  margin-bottom: 2px;
  pointer-events: all;
}
.Viewer .Thumbnail .Wrapper:hover .Actions > * {
  visibility: visible;
}

.Viewer .Thumbnail.drag-over .Border {
  border: solid black var(--over-border-width);
  background-color: #0003;
}





.Viewer .Thumbnail .Wrapper .CommentPreview {
  background-color: #fff9;
  padding: 16px;
  -webkit-backdrop-filter: blur(16px);
          backdrop-filter: blur(16px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.Viewer .Thumbnail .Wrapper .CommentPreview > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}
.Viewer .Thumbnail .Wrapper .CommentPreview > div + div {
  margin-top: 4px;
  border-top: 1px solid #000c;
  padding-top: 4px;
}

.Viewer .Page, 
.Viewer .Page main, 
.Viewer .Page header, 
.Viewer .Page footer, 
.Viewer .Page div {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  overflow: hidden;
  --over-border-width: 16px;
}

.Viewer .Page {
  width: var(--width);
  height: var(--height);
  background-color: #eee;
  margin-bottom: var(--app-margin);
}
.Viewer .Page > .Border {
  z-index: 1;
  pointer-events: none;
  transition: .2s;
}
.Viewer .Page.drag-over > .Border {
  transition: .2s;
  border: solid black var(--over-border-width);
}
@media print {
  .Viewer .Page > .Border,
  .Viewer .Page > .Actions {
    display: none;
  }
}


.Viewer .Page header,
.Viewer .Page footer {
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 32px;
}

.Viewer .Page header {
  flex: 0 0 24%;
  padding-top: 32px;
  justify-content: flex-start;
}

.Viewer .Page footer {
  flex: 0 0 18%;
  padding-bottom: 32px;
  justify-content: flex-end;
}

.Viewer .Page header .Top {
  border-bottom: var(--blue) solid 1.5px;
  align-items: flex-start;
  height: 24px;
  font-weight: bold;
  font-size: 18px;
}
.Viewer .Page header .Top a {
  color: inherit;
  text-decoration: none;
}
.Viewer .Page footer .Bottom {
  border-top: var(--blue) solid 1.5px;
  align-items: flex-end;
  height: 24px;
  font-weight: normal;
  font-size: 14px;
  padding-top: 4px;
}
.Viewer .Page header h1 {
  text-align: center;
  outline: none;
  font-weight: normal;
  padding-top: 60px;
}
.Viewer .Page header h1 .ExtraTitle {
  padding-left: 8px;
  opacity: .25;
}

.Viewer .Page main {
  width: 100%;
  flex: 1 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 96px;
  font-size: 14px;
}
.Viewer .Page.Thumbnails main {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Viewer .Page.Summary main p {
  padding-top: 16px;
} 
.Viewer .Page.Summary ul {
  font-size: 21px;
} 
.Viewer .Page.Summary ul li a {
  text-decoration: none;
  color: inherit;
} 
.Viewer .Page.Summary ul li a .Count {
  font-size: .5em;
  text-decoration: none;
  padding-left: 8px;
}



.Viewer {
  --width: calc(min(100vw - var(--app-margin) * 2, 100vh * 1.414213 - var(--app-margin) * 2));
  --height: calc(var(--width) / 1.414213);
  width: var(--width);
  min-height: 100vh;
  margin: auto;
}
@media print {
  .Viewer {
    --width: 29.7cm;
  }
}



.TopSummary {
  width: 100%;
  height: 100%;
  flex: 1 1;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Login {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Login > * + * {
  margin-top: 4px;
}

.Login > * > * + * {
  margin-left: 4px;
}

.Login button {
  padding: 0 32px;
}

.App {
  width: 100%;
  min-height: 100vh;
  font-family: 'PT Sans', sans-serif;
  --app-margin: 40px;
  padding: var(--app-margin) 0;
  --blue: #0d3283;
  --bright-blue: #0043d7;
  --dark-blue: #071a44;
  --light-blue: #bcd4f1;
  --gold: #e4ac5c;
  --gold-bright: #f9d57a;
  color: var(--dark-blue);
  background-color: var(--light-blue);
  display: flex;
  flex-direction: column;
}

@media print {
  .App {
    --app-margin: 0;
  }
}

body {
  background-color: var(--light-blue);
}

.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  cursor: pointer;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.modal-open {
  overflow: hidden;
}

button {
  font-family: inherit;
}

@media screen and (max-width: 1000px) {
  .App {
    --app-margin: 0;
    font-size: 12px;
  }
  .Viewer {
    --height: 1000px;
  }
  .Viewer .Page {
    margin-bottom: 64px;
  }
  .Viewer .Page main {
    padding: 0 32px;
  }
  .Viewer .Page.Thumbnails main {
    grid-template-columns: 1fr 1fr;
  }
  .Viewer .Page.Summary ul {
    font-size: 16px;
  }
  .Viewer .Page header .Top {
    font-size: 14px;
  }
  .Viewer .Page footer .Bottom {
    font-size: 11px;
  }
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, body * {
  position: relative;
  box-sizing: border-box;
  margin: 0;
}

