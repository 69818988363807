.Modal.ImageComment {
  display: flex;
  padding: 64px;
  font-family: 'PT Sans', sans-serif;
}
.Modal.ImageComment > * {
  flex: 1;
}
.Modal.ImageComment > * + * {
  margin-left: 32px;
}

.Modal.ImageComment .Image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.Modal.ImageComment .Comments {
  display: flex;
  flex-direction: column;
  pointer-events: none;
}
.Modal.ImageComment button,
.Modal.ImageComment .Comment {
  pointer-events: all;
}
.Modal.ImageComment .Comment {
  display: flex;
  flex-direction: row;
}

.Modal.ImageComment .Comment textarea, 
.Modal.ImageComment .Comment input {
  appearance: none;
  border: solid 1px #0003;
  border-radius: 2px;
  resize: none;
  font-family: inherit;
  padding: 4px 8px;
  outline: none;
  opacity: .75;
}
.Modal.ImageComment .Comment textarea:focus, 
.Modal.ImageComment .Comment input:focus {
  opacity: 1;
}
.Modal.ImageComment .Comment input {
  flex: 0 0 100px;
}
.Modal.ImageComment .Comment textarea {
  flex: 1;
}
.Modal.ImageComment .Comment textarea.modified, 
.Modal.ImageComment .Comment input.modified {
  border-color: #000c;
}
