@media screen and (max-width: 1000px) {
  .App {
    --app-margin: 0;
    font-size: 12px;
  }
  .Viewer {
    --height: 1000px;
  }
  .Viewer .Page {
    margin-bottom: 64px;
  }
  .Viewer .Page main {
    padding: 0 32px;
  }
  .Viewer .Page.Thumbnails main {
    grid-template-columns: 1fr 1fr;
  }
  .Viewer .Page.Summary ul {
    font-size: 16px;
  }
  .Viewer .Page header .Top {
    font-size: 14px;
  }
  .Viewer .Page footer .Bottom {
    font-size: 11px;
  }
}

