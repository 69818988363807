.Viewer .Thumbnail {
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
  font-size: 12px;
}

.Viewer .Thumbnail p {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PT Sans Narrow', sans-serif;
}
.Viewer .Thumbnail p.minimized {
  /* font-size: .66em; */
}

.Viewer .Thumbnail > div {
  flex: 1 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Viewer .Thumbnail .Wrapper img,
.Viewer .Thumbnail .Wrapper video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.Viewer .Thumbnail .Border {
  transition: .2s;
  pointer-events: none;
}
@media print {
  .Viewer .Thumbnail .Border,
  .Viewer .Thumbnail .Actions {
    display: none;
  }
}
.Viewer .Thumbnail .Wrapper .Actions {
  padding: 8px;
  align-items: flex-start;
  pointer-events: none;
}
.Viewer .Thumbnail .Wrapper .Actions > * {
  visibility: hidden;
  margin-bottom: 2px;
  pointer-events: all;
}
.Viewer .Thumbnail .Wrapper:hover .Actions > * {
  visibility: visible;
}

.Viewer .Thumbnail.drag-over .Border {
  border: solid black var(--over-border-width);
  background-color: #0003;
}





.Viewer .Thumbnail .Wrapper .CommentPreview {
  background-color: #fff9;
  padding: 16px;
  backdrop-filter: blur(16px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.Viewer .Thumbnail .Wrapper .CommentPreview > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}
.Viewer .Thumbnail .Wrapper .CommentPreview > div + div {
  margin-top: 4px;
  border-top: 1px solid #000c;
  padding-top: 4px;
}
