.Modal.ImageSolo {
  display: flex;
  flex-direction: column;
}
.Modal.ImageSolo > .Images {
  padding: 32px;
  width: 100%;
  /* height: 100%; */
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Modal.ImageSolo .ImageElement {
  display: block;
  min-width: 200px;
  min-height: 200px;
  max-height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: whitesmoke;
}
.Modal.ImageSolo .ImageElement.format9x16 {
  aspect-ratio: 9/16;
}
.Modal.ImageSolo .ImageElement.format4x5 {
  aspect-ratio: 4/5;
  max-height: 75%;
}
.Modal.ImageSolo .ImageElement a {
  display: block;
  width: 100%;
  height: 100%;
}
.Modal.ImageSolo .ImageElement img,
.Modal.ImageSolo .ImageElement video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Modal.ImageSolo .ImageElement + .ImageElement {
  margin-left: 32px;
}



.Modal.ImageSolo .ImageElement .Download {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 12px;
  justify-content: flex-end;
  background-color: var(--light-blue);
  color: var(--dark-blue);
  opacity: .98;
  transition: all 0.1s ease-out;
}
.Modal.ImageSolo .ImageElement .Download span {
  font-size: 12px;
}
.Modal.ImageSolo .ImageElement .Download .Icon {
  margin-left: 8px;
}
.Modal.ImageSolo .ImageElement .Download:hover {
  color: var(--bright-blue);
}
.Modal.ImageSolo .ImageElement .Download.hidden {
  opacity: 0;
}

.Modal.ImageSolo footer {
  background-color: var(--blue);
  color: var(--gold);
  padding: 12px;
}
.Modal.ImageSolo footer > * + * {
  margin-left: 16px;
}
.Modal.ImageSolo footer a {
  font-size: 12px;
  color: inherit;
  transition: all .2s ease-out;
  text-decoration: none;
}
.Modal.ImageSolo footer a:hover {
  color: var(--gold-bright);
}
.Modal.ImageSolo footer a > * + * {
  margin-left: 10px;
}


.Modal.ImageSolo .CloseModal {
  position: absolute;
  right: 0;
  top: 0;
}


.Modal.ImageSolo .NameParts {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  font-size: 8px;
  padding-right: 16px;
}
.Modal.ImageSolo .NameParts div.Part {
  display: flex;
  align-items: center;
  padding: 1px 0;
  border-bottom: solid 1px currentColor;
  border-top: solid 1px currentColor;
  cursor: pointer;
}
.Modal.ImageSolo .NameParts div.Part span {
  pointer-events: none;
  padding: 0;
}
.Modal.ImageSolo .NameParts div.Part.active {
  color: white;
  background-color: #fff2;
}

/* mobile */