@import url('https://fonts.googleapis.com/css2?family=PT+Sans+Narrow:wght@400;700&display=block');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=block');

.Viewer {
  --width: calc(min(100vw - var(--app-margin) * 2, 100vh * 1.414213 - var(--app-margin) * 2));
  --height: calc(var(--width) / 1.414213);
  width: var(--width);
  min-height: 100vh;
  margin: auto;
}
@media print {
  .Viewer {
    --width: 29.7cm;
  }
}


