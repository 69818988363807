.Viewer .Page, 
.Viewer .Page main, 
.Viewer .Page header, 
.Viewer .Page footer, 
.Viewer .Page div {
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  overflow: hidden;
  --over-border-width: 16px;
}

.Viewer .Page {
  width: var(--width);
  height: var(--height);
  background-color: #eee;
  margin-bottom: var(--app-margin);
}
.Viewer .Page > .Border {
  z-index: 1;
  pointer-events: none;
  transition: .2s;
}
.Viewer .Page.drag-over > .Border {
  transition: .2s;
  border: solid black var(--over-border-width);
}
@media print {
  .Viewer .Page > .Border,
  .Viewer .Page > .Actions {
    display: none;
  }
}


.Viewer .Page header,
.Viewer .Page footer {
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 0 32px;
}

.Viewer .Page header {
  flex: 0 0 24%;
  padding-top: 32px;
  justify-content: flex-start;
}

.Viewer .Page footer {
  flex: 0 0 18%;
  padding-bottom: 32px;
  justify-content: flex-end;
}

.Viewer .Page header .Top {
  border-bottom: var(--blue) solid 1.5px;
  align-items: flex-start;
  height: 24px;
  font-weight: bold;
  font-size: 18px;
}
.Viewer .Page header .Top a {
  color: inherit;
  text-decoration: none;
}
.Viewer .Page footer .Bottom {
  border-top: var(--blue) solid 1.5px;
  align-items: flex-end;
  height: 24px;
  font-weight: normal;
  font-size: 14px;
  padding-top: 4px;
}
.Viewer .Page header h1 {
  text-align: center;
  outline: none;
  font-weight: normal;
  padding-top: 60px;
}
.Viewer .Page header h1 .ExtraTitle {
  padding-left: 8px;
  opacity: .25;
}

.Viewer .Page main {
  width: 100%;
  flex: 1 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 96px;
  font-size: 14px;
}
.Viewer .Page.Thumbnails main {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Viewer .Page.Summary main p {
  padding-top: 16px;
} 
.Viewer .Page.Summary ul {
  font-size: 21px;
} 
.Viewer .Page.Summary ul li a {
  text-decoration: none;
  color: inherit;
} 
.Viewer .Page.Summary ul li a .Count {
  font-size: .5em;
  text-decoration: none;
  padding-left: 8px;
}


