.Login {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Login > * + * {
  margin-top: 4px;
}

.Login > * > * + * {
  margin-left: 4px;
}

.Login button {
  padding: 0 32px;
}
