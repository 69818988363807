.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #ccc6;
  backdrop-filter: blur(32px);
}
@supports not (backdrop-filter: blur(32px)) {
  .Modal {
    background-color: #cccc;
  }
}