.App {
  width: 100%;
  min-height: 100vh;
  font-family: 'PT Sans', sans-serif;
  --app-margin: 40px;
  padding: var(--app-margin) 0;
  --blue: #0d3283;
  --bright-blue: #0043d7;
  --dark-blue: #071a44;
  --light-blue: #bcd4f1;
  --gold: #e4ac5c;
  --gold-bright: #f9d57a;
  color: var(--dark-blue);
  background-color: var(--light-blue);
  display: flex;
  flex-direction: column;
}

@media print {
  .App {
    --app-margin: 0;
  }
}

body {
  background-color: var(--light-blue);
}

.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  cursor: pointer;
  user-select: none;
}

.modal-open {
  overflow: hidden;
}

button {
  font-family: inherit;
}
