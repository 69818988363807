.Icon {
  --color: currentColor;
  color: var(--color);
  display: flex;
}

.Icon > div {
  display: flex;
  border: solid 1px var(--color);
  border-radius: 4px;
  padding: 4px;
}

.Icon.hover:hover > div {
  color: var(--gold);
  border-color: white;
  background-color: white;
}

.Icon.gold {
  background-color: var(--gold);
  color: white;
}
